import { render, staticRenderFns } from "./custom-client-placeholder.vue?vue&type=template&id=466c7a12&scoped=true"
import script from "./custom-client-placeholder.vue?vue&type=script&lang=js"
export * from "./custom-client-placeholder.vue?vue&type=script&lang=js"
import style0 from "./custom-client-placeholder.vue?vue&type=style&index=0&id=466c7a12&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!./node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466c7a12",
  null
  
)

export default component.exports